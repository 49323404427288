.market-table-input {
  width: 100%;
  border: none;
  height: 48px;
  padding: 1.75px;
  font-size: 14px;
  text-align: center;
  &:focus {
    outline: 1px solid rgb(20, 128, 92);
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.inactive {
  background-color: #d7d7d7;
  color: #000;
}

.bg-primary {
  background-color: #147c5c;
  color: #fff;
}
